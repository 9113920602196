import style from './home.module.scss';
import React from 'react';

//Components
import Footer from '../../templates/footer/footer';
import NavBar from '../../templates/nav-bar/nav-bar';

import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

//Thumbnails
import Thumb1 from '../../../assets/images/video_1.png';
import Thumb2 from '../../../assets/images/video_2.png';
import Thumb3 from '../../../assets/images/video_3.png';
import Thumb4 from '../../../assets/images/video_4.png';

//Images
import PriscilaRizelo from '../../../assets/images/priscila_rizelo.jpeg';
import HenriquePetModerno from '../../../assets/images/henrique_pet_moderno.jpeg';
import Acabamento from '../../../assets/images/img-div.png';
import Acabamento2 from '../../../assets/images/img-div2.png';

//Icons
import IconeCao from '../../../assets/images/icone_cao.png';
import IconeGato from '../../../assets/images/icone_gato.png';
import AbstractPage from '../AbstractPage';


export default class Home extends AbstractPage {

    constructor(props) {
        super(props)

        this.state = {
            user: {
                name: '...',
                email: '...',
                phone: '...',
                type: 0
            },
        }
    }

    async componentDidMount() {
        await this.init();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <>
                <section className={style.header}>
                    <NavBar name={this.state.user.name} />
                    <div className={style.containerDefault + ' ' + style.zuccolo}>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg" data-aos="fade-up">
                                {
                                    this.state.user.type === 1
                                    ? <>
                                        <h1>TUDO O QUE VOCÊ PRECISA <br /> SABER PARA SEU PET <br /> VIVER FELIZ E SAUDÁVEL!</h1>
                                        <p>Henrique, fundador do Pet Moderno, preparou uma série de vídeos exclusivos para você garantir o bem-estar e adaptação do seu pet nessa nova fase.</p>
                                    </>
                                    : (
                                        this.state.user.type === 2
                                        ? <>
                                            <h1>AS MELHORES DICAS E<br /> INFORMAÇÕES PARA SEU<br /> GATO VIVER SAUDÁVEL!</h1>
                                            <p>Priscila Rizelo, Médica Veterinária e Coordenadora de Comunicação Científica da Royal Canin® Brasil, preparou um compilado de vídeoaulas repleto de dicas, curiosidades e informações relevantes para que você garanta o bem-estar e adaptação do seu felino nesta nova fase. </p>
                                        </>
                                        : ''
                                    )
                                }
                                <Link to="videos" spy={true} smooth={true} offset={50} duration={500} className={style.confira}><p>Aperte o play e confira!</p></Link>
                            </div>
                            <div className="col-lg" data-aos="fade-down">

                                {
                                    this.state.user.type === 1
                                        ? <img src={HenriquePetModerno} className="img-fluid" />
                                        : (
                                            this.state.user.type === 2
                                                ? <img src={PriscilaRizelo} className="img-fluid" />
                                                : ''
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Element name={"videos"}>
                    <section className={style.videos}>
                        <img src={Acabamento} className={style.acabamento} />
                        <div className={style.containerDefault + " d-flex justify-content-center flex-column"}>
                            <div className={style.caes + " d-flex justify-content-center align-items-center"}>
                                <hr />
                                {
                                    this.state.user.type === 1
                                        ? <img src={IconeCao} />
                                        : (
                                            this.state.user.type === 2
                                                ? <img src={IconeGato} />
                                                : ''
                                        )
                                }
                                <hr />
                            </div>
                            {
                                this.state.user.type === 1
                                    ? <>
                                        <div className={style.video + " row justify-content-center align-items-center"} data-aos="fade-right">
                                            <div className="col-lg-7 d-flex justify-content-center">
                                                <iframe src="https://player.vimeo.com/video/1037528286" width="100%"
                                                        height="550" frameBorder="0"
                                                        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className={style.text1 + " col-lg d-flex justify-content-center flex-column"}>
                                                <h2 className="mb-3">AS MELHORES DICAS<br /> PARA CUIDAR E EDUCAR<br /> SEU PET!</h2>
                                                <p>Aproveite dicas imperdíveis para<br /> educar, cuidar e compreender as necessidades<br /> do seu novo pet a partir de práticas e hábitos<br /> simples no seu dia a dia. Aperte o play e confira!</p>
                                            </div>
                                        </div>
                                        <p style={{textAlign: 'center'}}>Para maiores informações acesse:<br />
                                            <a target={"_blank"} rel={"noreferrer nofollow"} href={"https://petmoderno.com.br/"}>www.petmoderno.com.br</a><br />
                                            ou<br />
                                            <a target={"_blank"} rel={"noreferrer nofollow"} href={"https://petmoderno.com.br/escola-pet-moderno"}>www.petmoderno.com.br/escola-pet-moderno</a></p>
                                    </>
                                    : (
                                        this.state.user.type === 2
                                            ? <>
                                                <div className={style.video + " row justify-content-center align-items-center"} data-aos="fade-right">
                                                    <div className="col-lg-7 d-flex justify-content-center">
                                                        <iframe
                                                            src="https://player.vimeo.com/video/1037528248"
                                                            width="640" height="360" frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                    <div className={style.text1 + " col-lg d-flex justify-content-center flex-column"}>
                                                        <h2 className="mb-3">COMO PREPARAR O AMBIENTE PARA RECEBER SEU GATO EM CASA</h2>
                                                        <p>Aperte o play e conheça algumas curiosidades sobre o comportamento e necessidades dos gatos para uma melhor adaptação do seu pet ao novo lar.</p>
                                                    </div>
                                                </div>
                                                <div className={style.video + " row justify-content-center align-items-center mt-5"} data-aos="fade-right">
                                                    <div className="col-lg-7 d-flex justify-content-center">
                                                        <iframe
                                                            src="https://player.vimeo.com/video/1037528151"
                                                            width="640" height="360" frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                    <div className={style.text1 + " col-lg d-flex justify-content-center flex-column"}>
                                                        <h2 className="mb-3">5 PILARES PARA O BEM-ESTAR FELINO</h2>
                                                        <p>Confira algumas dicas fundamentais sobre as necessidades ambientais básicas dos gatos, como adaptação do local em que vivem e interação com humanos e outros animais.</p>
                                                    </div>
                                                </div>

                                                <div className={style.video + " row justify-content-center align-items-center mt-5"} data-aos="fade-right">
                                                    <div className="col-lg-7 d-flex justify-content-center">
                                                        <iframe
                                                            src="https://player.vimeo.com/video/1037528090"
                                                            width="640" height="360" frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                    <div className={style.text1 + " col-lg d-flex justify-content-center flex-column"}>
                                                        <h2 className="mb-3">ALIMENTAÇÃO PARA FELINOS</h2>
                                                        <p>Visando a boa alimentação dos gatos, Priscila Rizelo preparou um conteúdo completo com as principais características e maneiras de proporcionar bem-estar e nutrição aos pets. Confira!</p>
                                                    </div>
                                                </div>

                                                <div className={style.video + " row justify-content-center align-items-center mt-5"} data-aos="fade-right">
                                                    <div className="col-lg-7 d-flex justify-content-center">
                                                        <iframe
                                                            src="https://player.vimeo.com/video/1037528051"
                                                            width="640" height="360" frameBorder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    </div>
                                                    <div className={style.text1 + " col-lg d-flex justify-content-center flex-column"}>
                                                        <h2 className="mb-3">A IMPORTÂNCIA DO ACOMPANHAMENTO COM MÉDICO-VETERINÁRIO E COMO FAZÊ-LO</h2>
                                                        <p>O acompanhamento médico dos felinos é fundamental para estabelecer o bem-estar ao longo de toda a vida. Então, dê o play e veja como colocar isso em prática de maneira tranquila!</p>
                                                    </div>
                                                </div>
                                            </>
                                            : ''
                                    )
                            }
                        </div>
                    </section>

                </Element>
                <img src={Acabamento2} className={style.acabamento} />
                <Footer />
            </>
        )
    }
}
